var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page-list-authors" },
    [
      _c("h1", { staticClass: "title mb-4" }, [
        _vm._v(_vm._s(_vm.$t("lk.authors.title"))),
      ]),
      _vm.loading
        ? _c("CSpinner", {
            staticStyle: { position: "absolute" },
            attrs: { color: "primary", size: "lg" },
          })
        : _vm._e(),
      _vm.can_edit
        ? _c(
            "div",
            { staticClass: "page-list-authors__controls" },
            [
              _c(
                "CButton",
                {
                  staticClass: "mr-1",
                  attrs: {
                    size: "sm",
                    color: "primary",
                    disabled: _vm.loading,
                    to: `authors/create`,
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("general.add")) + " ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "d-flex flex-wrap justify-content-around" },
        _vm._l(_vm.items, function (item) {
          return _c(
            "div",
            { staticClass: "page-list-authors__item" },
            [
              _c("small-author", {
                staticClass: "page-list-authors__item-author",
                attrs: { author: item, back: { name: "authors" } },
              }),
              _c(
                "div",
                { staticClass: "pl-5" },
                [
                  _vm.can_edit
                    ? _c(
                        "CButton",
                        {
                          staticClass: "mr-1",
                          attrs: {
                            size: "sm",
                            color: "success",
                            disabled: _vm.loading,
                            to: `authors/${item.id}/edit`,
                          },
                        },
                        [_c("CIcon", { attrs: { name: "cil-pencil" } })],
                        1
                      )
                    : _vm._e(),
                  _vm.can_delete
                    ? _c(
                        "CButton",
                        {
                          attrs: {
                            color: "danger",
                            size: "sm",
                            disabled: _vm.loading,
                          },
                          on: {
                            click: function ($event) {
                              _vm.selectId = String(item.id)
                              _vm.selectName = item.id + ". " + item.fullName
                              _vm.showRemoveConfirmModal = true
                            },
                          },
                        },
                        [_c("CIcon", { attrs: { name: "cil-x" } })],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
      _c("RemoveConfirmModal", {
        attrs: {
          id: _vm.selectId,
          itemName: _vm.selectName,
          confirmationValue: _vm.selectName,
          show: _vm.showRemoveConfirmModal,
          loading: _vm.loading,
        },
        on: {
          close: function ($event) {
            _vm.showRemoveConfirmModal = false
          },
          success: _vm.removeItem,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }