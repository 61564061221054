<template>
  <div class="page-list-authors">
    <h1 class="title mb-4">{{ $t('lk.authors.title') }}</h1>
    <CSpinner v-if="loading" color="primary" size="lg" style="position: absolute" />
    <div class="page-list-authors__controls" v-if="can_edit">
      <CButton
        class="mr-1"
        size="sm"
        color="primary"
        :disabled="loading"
        :to="`authors/create`"
      >
        {{ $t('general.add') }}
      </CButton>
    </div>
    <div class="d-flex flex-wrap justify-content-around">
      <div v-for="item in items" class="page-list-authors__item">
        <small-author
          class="page-list-authors__item-author"
          :author="item"
          :back="{name: 'authors'}"
        />
        <div class="pl-5">
          <CButton
            v-if="can_edit"
            class="mr-1"
            size="sm"
            color="success"
            :disabled="loading"
            :to="`authors/${item.id}/edit`"
          >
            <CIcon name="cil-pencil" />
          </CButton>
          <CButton
            v-if="can_delete"
            color="danger"
            size="sm"
            :disabled="loading"
            @click="selectId = String(item.id); selectName = item.id + '. ' + item.fullName; showRemoveConfirmModal = true"
          >
            <CIcon name="cil-x" />
          </CButton>
        </div>

      </div>
    </div>
    <RemoveConfirmModal
      :id="selectId"
      :itemName="selectName"
      :confirmationValue="selectName"
      :show="showRemoveConfirmModal"
      :loading="loading"
      @close="showRemoveConfirmModal = false"
      @success="removeItem"
    />
  </div>
</template>

<script>
import axios from 'axios'
import SmallAuthor from "@/components/training/SmallAuthor"
import RemoveConfirmModal from "@/components/common/RemoveConfirmModal"
import Author from '@/assets/js/Author.class';

export default {
  name: "PageAuthorsList",
  computed: {
    Author () {
      return Author
    }
  },
  components: {
    SmallAuthor,
    RemoveConfirmModal
  },
  data() {
    return {
      items: [],
      loading: false,
      selectId: '',
      selectName: '',
      can_edit: false,
      can_delete: false,
      showRemoveConfirmModal: false
    }
  },
  async created () {
    await this.getItems()
  },
  methods: {
    async getItems(){
      this.loading = true
      const result = await axios.get('/api/v2/authors').catch(e => {})
      this.items = (result?.data?.data || []).map(item => new Author(item))
      this.can_edit = result?.data?.can_edit || false
      this.can_delete = result?.data?.can_delete || false
      this.loading = false
    },
    async removeItem(id){
      if(!id){
        this.showRemoveConfirmModal = false
        this.loading = false
        return
      }
      this.loading = true
      await axios.delete('/api/v2/authors/' + id).then(res => {
        this.$toastr.success(this.$t('general.deleted'))
      }).catch(e => {})
      this.showRemoveConfirmModal = false
      await this.getItems()
    }
  }
}
</script>

<style lang="scss" scoped>
.page-list-authors{
  &__item{
    min-width: 350px;
    padding: 30px;
    margin: 15px;
    border: 1px solid var(--table-active-row);
    border-radius: 5px;
  }
  &__item-author{
    margin-bottom: 10px;
  }
  &__controls{
    z-index: 999;
    position: absolute;
    top: 2.8rem;
    right: 1rem;
  }
}
</style>
